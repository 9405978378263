import React, { useState, useEffect } from "react";
import axios from 'axios'
import { useCookies } from "react-cookie";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

import { Header } from "../components";
import { ReportTypeList, GraphTypeList } from "../data/list"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement
} from 'chart.js';

import { Line, Bar, Pie } from 'react-chartjs-2';

import { Years } from "../data/list"
import { Query } from '@syncfusion/ej2-data';

const Home = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const [cookies] = useCookies()
  const [dataValue, setDataValue] = useState([])
  const [monthValue, setMonthValue] = useState([])
  const [yearValue, setYearValue] = useState("0")
  const [officeList, setOfficeState] = useState([])
  const [officeId, setOfficeId] = useState(cookies['__info'].role === 2 ? cookies['__info'].office.id : 0)
  const [reportTypeId, setReportTypeIdState] = useState(0)
  const [reportTypeText, setReportTypeText] = useState("SELECT REPORT TYPE")
  const [extName, setExtName] = useState("")
  const [graphTypeId, setGraphTypeId] = useState("Line")
  const office_fields = { text: 'name', value: 'id' }
  const report_type_fields = { text: 'name', value: 'id' };
  const graph_type_fields = { text: 'name', value: 'id' };
  const [chartData, setChartData] = useState(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement
  )
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: `${reportTypeText} ${yearValue}`,
        font: { size: 18 },
        color: '#333'
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }
  
  const labels = monthValue && monthValue;
  
  const data = {
    labels,
    datasets: chartData ? chartData : [{
                              label: `${extName}`,
                              data: dataValue && dataValue,
                              borderColor: 'rgb(255, 99, 132)',
                              backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            }],
  };

  useEffect(() => {
    if (cookies['__info'].role === 2) {
      showEnergyConsumptionList(officeId, yearValue)
    }

    showOfficeList()
  }, [])

  const showEnergyConsumptionList = (officeVal, yearVal) => {
      api.get('get/electric-consumption/' + officeVal + '/' + yearVal)
      .then(res => {
        setDataValue(res.data.data)
        setMonthValue(res.data.month)

        const datasets = []

        datasets.push({
          label: `${extName}`,
          data: res.data.data && res.data.data,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        });

        if (res.data.data2 && res.data.data2.length > 0) {
          datasets.push({
            label: `${"Kg CO₂ per Kwh"}`,
            data: res.data.data2 && res.data.data2,
            borderColor: 'rgb(6, 230, 54)',
            backgroundColor: 'rgba(6, 230, 54, 0.5)',
          })
        }

        setChartData(datasets)
      })
      .catch(err => {
          console.log(err)
      })
  }

  const showWaterConsumptionList = (officeVal, yearVal) => {
    api.get('get/water-consumption/' + officeVal + '/' + yearVal)
    .then(res => {
      setDataValue(res.data.data)
      setMonthValue(res.data.month)

      const datasets = []

      datasets.push({
        label: `${extName}`,
        data: res.data.data && res.data.data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      });

      if (res.data.data2 && res.data.data2.length > 0) {
        datasets.push({
          label: `${"Kg CO₂ per Kwh"}`,
          data: res.data.data2 && res.data.data2,
          borderColor: 'rgb(6, 230, 54)',
          backgroundColor: 'rgba(6, 230, 54, 0.5)',
        })
      }

      setChartData(datasets)
    })
    .catch(err => {
        console.log(err)
    })
  }
  const showEnergySavingMeasure = (officeVal, yearVal) => {
    api.get('get/energy-saving-measure/' + officeVal + '/' + yearVal)
    .then(res => {
      setDataValue(res.data.data)
      setMonthValue(res.data.month)
    })
    .catch(err => {
        console.log(err)
    })
  }

  const showOtherResources = (officeVal, yearVal) => {
    api.get('get/other-resource/' + officeVal + '/' + yearVal)
    .then(res => {
      setDataValue(res.data.data)
      setMonthValue(res.data.month)

      const datasets = []

      datasets.push({
        label: `${extName}`,
        data: res.data.data && res.data.data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      });

      if (res.data.data2 && res.data.data2.length > 0) {
        datasets.push({
          label: `${"Kg CO₂ per Kwh"}`,
          data: res.data.data2 && res.data.data2,
          borderColor: 'rgb(6, 230, 54)',
          backgroundColor: 'rgba(6, 230, 54, 0.5)',
        })
      }

      setChartData(datasets)
    })
    .catch(err => {
        console.log(err)
    })
  }

  const showOfficeList = () => {
    api.get('get/office/' + cookies['__info'].campus_id)
    .then(res => {
      setOfficeState(res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }

  const onHandleChange = (e) => {
    const {name, value, text} = e.target

    if (name === "office_id") {
      setOfficeId(value)
      showReportTypeData(reportTypeId, value, yearValue)
    } else if (name === "report_type_id") {
      setReportTypeText(text)
      setReportTypeIdState(value) 

      if (officeId !== 0) {
        showReportTypeData(value, officeId, yearValue)
      }
    } else if (name === "graph_type_id") {
      setGraphTypeId(value)
    } else {
      setYearValue(value)

      if (officeId !== 0) {
        showReportTypeData(reportTypeId, officeId, value)
      }
    }
  }

  const showReportTypeData = (rt_id, office_id, year_id) => {
    if (rt_id === 1) {
      showEnergyConsumptionList(office_id, year_id) 
      setExtName("(Kwh)")
    } else if (rt_id === 2) {
      showWaterConsumptionList(office_id, year_id)
      setExtName("(m³)")
    } else if (rt_id === 3) {
      showEnergySavingMeasure(office_id, year_id)
      setExtName("")
      setChartData(null)
    } else if (rt_id === 4) {
      showOtherResources(office_id, year_id)
      setExtName("(% of spoilage)")
      setChartData(null)
    }
  }

  const onFiltering = FilteringEventArgs => {
    let query = new Query();
    query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
    FilteringEventArgs.updateData(officeList, query);
  }

  return (
    <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Dashboard" title="Home" />

      {
        cookies['__info'].role === 1 &&
        (
          <div className="flex flex-row space-x-1 mb-2 justify-center border-solid border-1 dark:border-neutral-500 px-3 py-1">
            <DropDownListComponent style={{fontSize: "15px"}} name="office_id" id="office_id" onChange={onHandleChange} dataSource={officeList} fields={office_fields} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} placeholder="Select a office" />   
          </div>
        )
      }
      
      <div className="flex flex-row space-x-1 mb-2 justify-center border-solid border-1 dark:border-neutral-500 px-3 py-1">
        <DropDownListComponent style={{fontSize: "15px"}} name="report_type_id" id="report_type_id" onChange={onHandleChange} dataSource={ReportTypeList} fields={report_type_fields} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search report type...' allowFiltering={true} placeholder="Select report type" />   
      </div>
    
      <div className="flex flex-row space-x-1 mb-2 justify-center">
        <select id="year" name="year" value={yearValue} onChange={onHandleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          <option value="0" disabled>Select a year</option>
          {
            Years.map((result, index) => {
              return (
                <option value={result} key={index}> {result} </option>
              )
            })
          }
        </select>
      </div>
      
      <div className="flex flex-row space-x-1 mb-2 justify-center border-solid border-1 dark:border-neutral-500 px-3 py-1">
        <DropDownListComponent style={{fontSize: "15px"}} name="graph_type_id" id="graph_type_id" onChange={onHandleChange} value={graphTypeId} dataSource={GraphTypeList} fields={graph_type_fields} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search graph type...' allowFiltering={true} placeholder="Select graph type" />   
      </div>

      <div className="w-full max-w-8xl bg-white p-4 shadow-lg rounded-lg">
        { graphTypeId === "Line" && <Line options={options} data={data} /> }
        { graphTypeId === "Bar" && <Bar options={options} data={data} /> }
        { graphTypeId === "Pie" && <Pie options={options} data={data} /> }
      </div>
    </div>  
  )
}

export default Home;
