import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import { jwtDecode } from "jwt-decode";

const Login = () => {
    const [cookies, setCookie] = useCookies(['__user'])
    const navigate = useNavigate()

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true
    })

    const handleLoginSuccess = (response) => {
        const info = jwtDecode(response?.credential);
        
        if (info) {
            let user_info = {
                'email': info.email,
                'picture': info.picture,
                'name': info.name,
            }

            validateLogin(user_info)
        }
    }
    const handleLoginError = () => {
        console.log("Google login failed")
    }

    const validateLogin = async (params) => {
        let res = await api.post('post/validate-login', {params})
        
        if (res.status === 200) {
            setCookie('__info', res.data.credentials.info)
            setCookie('__access_token', res.data.credentials.access_token)

            navigate('/home', { replace: true })
        } else {
            alert("Unathorized User. Please contact your administrator.")
        }
    }

    return (
        <GoogleOAuthProvider clientId="526735068340-7cgt7p64pbfjbvfl52dib8jq9522vcm4.apps.googleusercontent.com">
            <div className="flex items-center justify-center min-h-screen">
                <div className="relative bg-white/30 backdrop-blur-lg shadow-lg rounded-xl p-10 w-full max-w-md text-center">
                    <div className="absolute inset-0 bg-white/20 border rounded-xl backdrop-blur-xl -z-10"></div>
                    
                    <h1 className="text-3xl font-semibold text-gray-800 mb-4">Welcome Back!</h1>
                    <p className="text-gray-600 mb-8">Login to your account using Google</p>
                    
                    <div className="flex items-center justify-center transition-all duration-300 transform hover:-translate-y-1 hover:scale-105">
                        <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginError}
                        />
                    </div>
                    <div className="mt-6 text-sm text-gray-500">
                    {/* <p>By continuing, you agree to our <a href="#" className="text-blue-500 hover:underline">Terms of Service</a> and <a href="#" className="text-blue-500 hover:underline">Privacy Policy</a>.</p> */}
                    </div>
                </div>
            </div>
        </GoogleOAuthProvider>
    )
}

export default Login
