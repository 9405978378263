import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from 'axios'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Search, Page } from '@syncfusion/ej2-react-grids';
import Papa from "papaparse"

import { Query } from '@syncfusion/ej2-data';
import { Header } from "../components";
import { MonthsName } from "../data/list"
import ModalUpload from "./ModalUpload";

const WaterConsumption = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    const [cookies] = useCookies()
    const [WaterConsumptionList, setWaterConsumptionState] = useState([])
    const [officeList, setOfficeState] = useState([])
    const office_fields = { text: 'name', value: 'id' };
    const [isUpload, setIsUpload] = useState(false)
    const [uploadParams, setUploadParams] = useState({
        year: [],
        month: [],
        meter_no: [],
        cum: [],
        total_cost: [],
        total_line: 0
    })

    useEffect(() => {
        showOfficeList()

        if (cookies['__info'].role === 2) {
            showWaterConsumptionList()
        }
    }, [])
    
    const showOfficeList = () => {
        api.get('get/office/' + cookies['__info'].campus_id)
        .then(res => {
            setOfficeState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showWaterConsumptionList = () => {
        api.get('get/water/' + cookies['__info'].office.id)
        .then(res => {
            setWaterConsumptionState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const showWaterConsumptionByOffice = (id) => {
        api.get('get/water-by-office/' + id)
        .then(res => {
            setWaterConsumptionState(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const onHandleChange = (e) => {
        const {name, value} = e.target

        if (name === "office_id") {
            showWaterConsumptionByOffice(value)
        } 
    }
    
    const onUploadCSV = () => {
        setIsUpload(true) 
    }

    const onReadingCSV = async () => {
        let res = await api.post('post/upload-water-comsumption', {uploadParams})
        console.log(res)
        if (res.status === 200) {
            if (res.data.is_success === 1) {
                alert("New water consumption has been successfully uploaded.")
                setIsUpload(false) 
            } else {
                alert("Error while updating record, Please try again")
            }
        } else {
            alert("Error while updating record, Please try again")
        }
    }

    const handleFileChange = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                results.data.map((val) => {
                    uploadParams.year.push(val.year)
                    uploadParams.month.push(val.month)
                    uploadParams.meter_no.push(val.meter_no)
                    uploadParams.cum.push(val.cum)
                    uploadParams.total_cost.push(val.total_cost)
                });

                uploadParams.total_line = results.data.length
            },
        })
    }

    const onCancelUpload = () => {
        setIsUpload(false) 
    }

    const onFiltering = FilteringEventArgs => {
        let query = new Query();
        query = (FilteringEventArgs.text !== '') ? query.where('name', 'startswith', FilteringEventArgs.text, true) : query;
        FilteringEventArgs.updateData(officeList, query);
    }
    
    const dateTemplate = ((props) => {
        return MonthsName[props.month-1].name;
    })
    
    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            {
                isUpload && <ModalUpload onCancelUpload={onCancelUpload} onReadingCSV={onReadingCSV} handleFileChange={handleFileChange} />
            }

            <div className="flex">
                <div className="flex-none">
                    <Header category="Pages" title="Water Consumption" />
                </div>
                {
                    cookies['__info'].role === 1 &&
                    <div className="flex-1">
                        <div className="flex justify-end">
                            <button onClick={onUploadCSV} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
                                <span className="relative px-3 py-1.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                    Upload CSV
                                </span>
                            </button>
                        </div>
                    </div>
                }
            </div>

            {
                cookies['__info'].role === 1 &&
                <div className="flex mb-4">
                    <div className="flex-1 border-solid border-1 dark:border-neutral-500 px-3 py-1">
                        <DropDownListComponent style={{fontSize: "15px"}} name="office_id" id="office_id" onChange={onHandleChange} dataSource={officeList} fields={office_fields} filtering={onFiltering.bind(this)} filterBarPlaceholder='Search a office' allowFiltering={true} placeholder="Select a office" />   
                    </div>
                </div>
            }
            
            <GridComponent 
            dataSource={WaterConsumptionList}
            width="auto"
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='meter_no' headerText='Meter No.' />
                    <ColumnDirective field='cum' textAlign="Center" headerText='Cubic Meter (m3)' />
                    <ColumnDirective field='month' textAlign="Center" headerText='Month' template={dateTemplate} />
                    <ColumnDirective field='year' textAlign="Center" headerText='Year' />
                </ColumnsDirective>
                <Inject services={[Search, Page]} />

            </GridComponent>
        </div>
    )
}

export default WaterConsumption