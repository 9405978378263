import React from 'react';
import { FiHome } from 'react-icons/fi';
import { BsFillInfoSquareFill, BsFillInboxFill, BsCardChecklist, BsBuilding, BsWater } from 'react-icons/bs';
import { IoIosSchool, IoIosHome, IoIosTrash, IoIosWater, IoIosFlash, IoMdSpeedometer, IoIosPerson } from 'react-icons/io';

export var Years = []

const showYears = () => {
  for (var i = new Date().getFullYear(); i >= 2010; i--) {
    Years.push(i)
  }
}

showYears()

export const gridOrderImage = (props) => (
  <div>
    <img
      className="rounded-xl h-20 md:ml-3"
      src={props.ProductImage}
      alt="order-item"
    />
  </div>
)

export const gridOrderStatus = (props) => (
  <button
    type="button"
    style={{ background: props.StatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.Status}
  </button>
)

export const links = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'home',
        label: 'Home',
        icon: <FiHome />,
      },
    ],
  },
  {
    title: 'Data Entries',
    links: [
      {
        name: 'energy-saving',
        label: 'Energy Saving Measure',
        icon: <IoIosHome />,
      },
      {
        name: 'other-resource',
        label: 'Other Resources',
        icon: <IoIosSchool />,
      },
      {
        name: 'solid-waste-mgt',
        label: 'Solid Waste Management',
        icon: <IoIosTrash />,
      },
    ],
  },
  {
    title: 'Pages',
    links: [
      {
        name: 'energy-meter',
        label: 'Energy Meter',
        icon: <BsBuilding />,
      },
      {
        name: 'water-meter',
        label: 'Water Meter',
        icon: <BsWater />,
      },
      {
        name: 'office',
        label: 'Office',
        icon: <IoIosHome />,
      },
      {
        name: 'campus',
        label: 'Campus',
        icon: <IoIosSchool />,
      },
    ],
  },
  {
    title: 'Consumption',
    links: [
      {
        name: 'energy',
        label: 'Energy Consumption',
        icon: <IoIosFlash />,
      },
      {
        name: 'water',
        label: 'Water Consumption',
        icon: <IoIosWater />,
      },
    ],
  },
  {
    title: 'My Account',
    links: [
      {
        name: 'meter-details',
        label: 'Meter Details',
        icon: <IoMdSpeedometer />,
      },
      // {
      //   name: 'profile',
      //   label: 'Profile',
      //   icon: <IoIosPerson />,
      // },
    ],
  },
  {
    title: 'Reports',
    links: [],
  }
]

export const chatData = [
 
]

export const themeColors = [
  {
    name: 'blue-theme',
    color: '#1A97F5',
  },
  {
    name: 'green-theme',
    color: '#03C9D7',
  },
  {
    name: 'purple-theme',
    color: '#7352FF',
  },
  {
    name: 'red-theme',
    color: '#FF5C8E',
  },
  {
    name: 'indigo-theme',
    color: '#1E4DB7',
  },
  {
    color: '#FB9678',
    name: 'orange-theme',
  },
]

export const userProfileData = [
  {
    icon: <BsFillInfoSquareFill />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
  },
  {
    icon: <BsFillInboxFill />,
    title: 'My Inbox',
    desc: 'Messages',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
]

export const energySavingMeasureQuestion = [
  { id: 1, name: 'Air conditioning unit/s are turned on from 9:00am to 4:00pm'},
  { id: 2, name: 'Observance of "one-day a week aircon holiday"'},
  { id: 3, name: 'Observance of earth hour, at 12:00nn to 1pm(1st week-Monday, 2nd Week-Tuesday, 3rd week-Wednesday and 4th week-Thursday)'},
  { id: 4, name: 'Lights are turned off before leaving the office'},
  { id: 5, name: 'Electric Fan/s are turned off when not in use'},
  { id: 6, name: 'Electrical Water dispenser/s are unplugged before leaving the office'},
  { id: 7, name: 'Computers and monitors are turned off at the end of the work day'},
  { id: 8, name: 'Printers and copiers are turned off when not in use'},
  { id: 9, name: 'No playing of computer games.'},
  { id: 10, name: 'No staying in the office between 6:00pm to 7:00am, unless allowed.'},
]

export const otherResourcesQuestion = [
  { id: 1, name: 'Number of pieces of paper used' },
  { id: 2, name: 'Number of pieces of properly use paper' },
  { id: 3, name: 'Number of pieces of paper with one side clean of print or marks available for reuse' },
  { id: 4, name: 'Number of pieces of paper reused/recycled' },
  { id: 5, name: 'Number of spoilage (pieces of paper) due to error' },
  { id: 6, name: 'Number of documents send through online' },
]

export const solidWasteMgtQuestion = [
  { id: 1, name: 'With separate bin for each type of waste' },
  { id: 2, name: 'Waste are properly segregated' },
  { id: 3, name: 'Waste are properly stored before pickup' },
  { id: 4, name: 'Correct trash bins are placed outside the office on the scheduled collection days' },
  { id: 5, name: 'Hazardous waste are properly stored' },
]

export const MonthsName = [
  { id: 1, name: 'January' },
  { id: 2, name: 'Feburary' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },  
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
]

export const ReportTypeList = [
  { id: 1, name: 'Energy Consumption and Carbon Footprint' },
  { id: 2, name: 'Water Consumption and Carbon Footprint' },
  { id: 3, name: 'Energy Saving Measure' },
  { id: 4, name: 'Other Resources (Paper Spoilage) and Carbon Footprint' },
  // { id: 5, name: 'Solid Waste Management' },
]

export const GraphTypeList = [
  { id: 'Line', name: 'Line Chart' },
  { id: 'Bar', name: 'Bar Chart' },
  { id: 'Pie', name: 'Pie Chart' },
]