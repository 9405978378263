import React, { useState, useEffect } from "react"
import { useCookies } from "react-cookie"
import axios from 'axios'
import { FaUser, FaEnvelope, FaUserTag, FaLock, FaBuilding, FaUniversity, FaUserShield, FaEye, FaEyeSlash } from "react-icons/fa";

import { Header } from "../components"
const UserDetails = () => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    // const [user, setUser] = useState({
    //     name: "John Doe",
    //     email: "john.doe@example.com",
    //     username: "johndoe",
    //     password: "12345678",
    //     office: "IT Department",
    //     campus: "Main Campus",
    //     role: "Administrator",
    // })

    const [user, setUser] = useState([])
    const [cookies] = useCookies()
    const [showPassword, setShowPassword] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editUser, setEditUser] = useState(user);

    useEffect(() => {
        showUserDetails()
    }, [])

    const showUserDetails = async () => {
        let res = await api.post('post/user-details', { id: cookies['__info'].id })

        if (res.status === 200) {
            setUser(res.data)
        }
    }

    const handleChange = (e) => {
        setEditUser({ ...editUser, [e.target.name]: e.target.value });
    }

    const handleSave = () => {
        setUser(editUser);
        setIsEditing(false);
    }
    
    const DetailItem = ({ icon, label, value }) => (
        <div className="border-b pb-4 flex items-center gap-3">
            {icon}
            <div className="w-full">
                <p className="text-lg font-semibold text-gray-700">{label}:</p>
                <p className="text-gray-600">{value}</p>
            </div>
        </div>
    )
    
    const InputField = ({ label, name, type = "text", value, onChange }) => (
        <div>
            <label className="block text-gray-700 font-medium">{label}:</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-400"
            />
        </div>
    )

    return (
        <div className="m-2 m-4 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="My Account" />

            <div className="flex flex-col items-center justify-center bg-gray-100 p-6">
                <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
                        User Details
                    </h2>

                    <div className="space-y-4">
                        <DetailItem icon={<FaUser />} label="Name" value={user.name} />
                        <DetailItem icon={<FaEnvelope />} label="Email" value={user.email} />
                        <DetailItem icon={<FaUserTag />} label="Username" value={user.email} />
                        
                        <div className="border-b pb-4 flex items-center gap-3">
                            <FaLock className="text-gray-500" />
                            <div className="w-full">
                                <p className="text-lg font-semibold text-gray-700">Password:</p>
                                <div className="flex items-center gap-2">
                                    <p className="text-gray-600">
                                        {showPassword ? "" : "••••••••"}
                                    </p>
                                    <button 
                                        onClick={() => setShowPassword(!showPassword)} 
                                        className="text-blue-500 text-sm hover:underline"
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <DetailItem icon={<FaBuilding />} label="Office" value={user.office && user.office.name} />
                        <DetailItem icon={<FaUniversity />} label="Campus" value={user.campus && user.campus.campus_name} />
                        <DetailItem icon={<FaUserShield />} label="User Role" value={user.role === 1 ? "Administrator" : "Office"} />
                    </div>

                    <button 
                        className="w-full bg-blue-500 text-white py-2 mt-4 rounded-lg hover:bg-blue-600 transition"
                        onClick={() => setIsEditing(true)}
                    >
                        Edit Profile
                    </button>
                </div>

                {isEditing && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                            <h3 className="text-xl font-semibold mb-4">Edit Profile</h3>
                            <form className="space-y-4">
                                <InputField label="Name" name="name" value={editUser.name} onChange={handleChange} />
                                <InputField label="Email" name="email" value={editUser.email} onChange={handleChange} />
                                <InputField label="Username" name="username" value={editUser.username} onChange={handleChange} />
                                <InputField label="Password" name="password" type="password" value={editUser.password} onChange={handleChange} />
                                <InputField label="Office" name="office" value={editUser.office} onChange={handleChange} />
                                <InputField label="Campus" name="campus" value={editUser.campus} onChange={handleChange} />
                                <InputField label="User Role" name="role" value={editUser.role} onChange={handleChange} />
                            </form>

                            <div className="flex justify-end mt-4 space-x-2">
                                <button 
                                    onClick={() => setIsEditing(false)}
                                    className="bg-gray-400 text-white px-4 py-2 rounded hover:bg-gray-500"
                                >
                                    Cancel
                                </button>
                                <button 
                                    onClick={handleSave}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserDetails